<template>
	<v-layout class="d-flex setting-layout">
		<div class="setting-sidebar">
			<v-tabs
				vertical
				v-model="settingTab"
				background-color="red"
				class="w-100"
				active-class="blue lighten-4"
			>
				<!-- <v-tab href="#personal_setting">Personal Setting</v-tab> -->
				<v-tab href="#company_details">Company Details</v-tab>
				<v-tab href="#role_permission">Role & Permission</v-tab>
				<v-tab href="#email_template">Email Template</v-tab>
			</v-tabs>
		</div>
		<div class="setting-content">
			<v-tabs-items v-model="settingTab">
				<!-- <v-tab-item value="personal_setting">
                   <PersonalSettings></PersonalSettings>
                </v-tab-item> -->
				<v-tab-item value="company_details">
					<CompanySetting></CompanySetting>
				</v-tab-item>
				<v-tab-item value="role_permission">
					<RolePermission></RolePermission>
				</v-tab-item>
				<v-tab-item value="email_template">
					<EmailTemplate></EmailTemplate>
				</v-tab-item>
			</v-tabs-items>
		</div>
	</v-layout>
</template>
<script>
import CompanySetting from "@/view/module/setting/company/CompanySetting";
//import PersonalSettings from "@/view/module/setting/personal/PersonalSettings"
import EmailTemplate from "@/view/module/setting/email/EmailTemplate";
import RolePermission from "@/view/module/setting/rolePermission/RolePermission";
export default {
	name: "AllSettings",
	components: {
		CompanySetting,
		//PersonalSettings,
		EmailTemplate,
		RolePermission,
	},
	created() {
		const _this = this;
		if (_this.$route.query && _this.$route.query.tab) {
			_this.settingTab = _this.$route.query.tab;
		}
	},
	computed: {
		settingTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.settingTab) {
					this.$router.push({ query });
				}
			},
			get() {
				return this.$route.query.tab || "company_details";
			},
		},
	},
};
</script>
